import { useContent } from "@app/hooks/useContent"
import { memo } from "react"

import type { Props } from "@app/pages/account/invalid_token"
import type { PageProps } from "@root/types/global"

const AccountInvalid: React.FC<PageProps<Props>> = ({ page }) => {
  const { getContent } = useContent()
  const content = getContent({ content: page?.content })

  return (
    <>
      <h1>{page?.title}</h1>
      {content}
    </>
  )
}

export default memo(AccountInvalid)
